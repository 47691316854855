import htmx, { HttpVerb } from 'htmx.org'
import classNames from 'classnames'

interface ClearLinkProps {
  setIsOpen: (val: boolean) => void
  title: string
}

const ClearLink = ({ setIsOpen, title }: ClearLinkProps) => {
  const params = new URLSearchParams(window.location.search)

  const handleClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setIsOpen(false)

    await htmx.ajax('GET' as HttpVerb, `/blog`, {
      target: '#articles-container',
      select: '#articles-container',
      swap: 'innerHTML',
    })
  }

  return (
    <a
      className={classNames(
        `rounded px-2 py-1 hover:bg-me-purple-700 hover:text-white hover:no-underline text-me-black`,
        {
          'bg-me-purple-700 text-white': !params.get('topic'),
        }
      )}
      href="/blog"
      onClick={handleClick}
    >
      {title}
    </a>
  )
}

export default ClearLink
