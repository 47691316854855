import htmx, { HttpVerb } from 'htmx.org'
import classNames from 'classnames'

interface TopicLinkProps {
  slug: string
  title: string
  setIsOpen: (val: boolean) => void
}

const TopicLink = ({ slug, title, setIsOpen }: TopicLinkProps) => {
  const params = new URLSearchParams(window.location.search)
  const topicSlug = params.get('topic')

  const handleClick = async (e: React.MouseEvent<HTMLElement>) => {
    const articlesList = document.querySelector('#articles-list')

    e.preventDefault()
    articlesList?.classList.toggle('.hx-request')

    setIsOpen(false)

    await htmx.ajax('GET' as HttpVerb, `/blog?topic=${slug}`, {
      target: '#articles-container',
      select: '#articles-container',
      swap: 'innerHTML',
    })

    articlesList?.classList.toggle('.hx-request')
  }

  return (
    <a
      className={classNames(
        `rounded px-2 py-1 hover:bg-me-purple-700 hover:text-white hover:no-underline text-me-black`,
        {
          'bg-me-purple-700 text-white': topicSlug && topicSlug.length > 0 && slug === topicSlug,
        }
      )}
      href={`/blog?topic=${slug}`}
      onClick={handleClick}
    >
      {title}
    </a>
  )
}

export default TopicLink
