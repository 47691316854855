import { createRoot } from 'react-dom/client'
import TopicsFilterModal from 'islands/topics_filter'

function renderTopicsFilter() {
  const domNode = document.getElementById('topics-filter')

  if (domNode) {
    const root = createRoot(domNode)
    const topics = JSON.parse(domNode.dataset?.topics || '{}')
    const modalTitle = domNode.dataset?.modalTitle || ''
    const all = domNode.dataset?.all || ''

    root.render(<TopicsFilterModal label={domNode.innerText} topics={topics} modalTitle={modalTitle} all={all} />)
    domNode.className = ''
  }
}

document.addEventListener('htmx:afterSettle', renderTopicsFilter)
document.addEventListener('DOMContentLoaded', renderTopicsFilter)
window.addEventListener('htmx:historyRestore', renderTopicsFilter)
