import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { useState } from 'react'
import { AdjustmentsVerticalIcon } from '@heroicons/react/24/outline'
import ClearLink from './clear_link'
import TopicLink from './topic_link'

type Topic = {
  slug: string
  title: string
}

interface TopicsFilterModalProps {
  label: string
  topics: Topic[]
  modalTitle: string
  all: string
}

const TopicsFilterModal = ({ label, topics, modalTitle, all }: TopicsFilterModalProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen(true)
  }

  return (
    <>
      <button onClick={handleClick} className="bg-me-purple-300 p-3 rounded-full flex gap-2 items-center">
        <AdjustmentsVerticalIcon className="h-5 w-5" />
        <span>{label}</span>
      </button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
        <div className="fixed inset-0 w-screen overflow-y-auto p-4">
          <div className="flex min-h-full items-center justify-center">
            <DialogPanel className="w-full max-w-5xl space-y-4 border bg-white py-5 rounded-lg mx-4">
              <DialogTitle className="font-bold text-2xl px-6 pb-5 relative pr-8">
                <span>{modalTitle}</span>
                <button className="absolute top-0 right-0 px-4" onClick={() => setIsOpen(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                  </svg>
                </button>
              </DialogTitle>
              <div className="flex flex-col gap-2 mx-4">
                {topics.map((topic) => (
                  <TopicLink key={topic.slug} slug={topic.slug} title={topic.title} setIsOpen={setIsOpen} />
                ))}
                <ClearLink setIsOpen={setIsOpen} title={all} />
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default TopicsFilterModal
